import { useState, useEffect } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { gql, useQuery } from '@apollo/client'
import { captureException } from '@sentry/nextjs'
import { Swiper, SwiperSlide } from 'swiper/react'
import { LABOR_ILLUSION_DELAY, SWIPER_IMAGE_SIZES } from 'lib/constants'
import SwiperLoading from 'components/loading.swiper.comp'
import ErrorMessage from 'components/errorMessage'

const PAGE_SIZE = 25
const LIST_TOP_LIKED_ARTISTS_QUERY = gql`
  query listTopLikedArtists ($page: Int!, $pageSize: Int!, $since: AWSDateTime!) {
    listTopLikedArtists(page: $page, pageSize: $pageSize, since: $since) {
      id
      name
      slug
      imageUrl
      likes
      songs
    }
  }
`

const TopLikedArtists = () => {
  // labor illusion state variables
  const [laborIllusion, setLaborIllusion] = useState(false)

  // set since date
  const since = new Date()
  since.setHours(0, 0, 0, 0)
  since.setMonth(since.getMonth() - 1)

  // set query variables
  const vars = {
    page: 1,
    pageSize: PAGE_SIZE,
    since: since.toISOString(),
  }

  // excute query
  const { loading, error, data } = useQuery(
    LIST_TOP_LIKED_ARTISTS_QUERY,
    {
      variables: vars,
    }
  )

  useEffect(() => {
    if (!data?.listTopLikedArtists?.length) {
      setLaborIllusion(true)
      setTimeout(() => {
        setLaborIllusion(false)
      }, LABOR_ILLUSION_DELAY)
    }
  }, [])

  // initial loading
  if (loading || laborIllusion) {
    return (
      <div className="category">
        <div className="category-caption">
          <span>أشهر الفنانين</span>
        </div>
        <div className="category-content">
          <SwiperLoading />
        </div>
      </div>
    )
  }

  // error handling
  if (error) {
    captureException(error)
    return <ErrorMessage />
  }

  // in case no data found
  if (!data?.listTopLikedArtists?.length) {
    return null
  }

  // get data
  const { listTopLikedArtists } = data

  // display data
  return (
    <div className="category">
      <div className="category-caption">
        <span>أشهر الفنانين</span>
      </div>

      <div className="category-content">
        <Swiper spaceBetween={40} slidesPerView={2.7} breakpoints={{ 767: { slidesPerView: 3.7 }, 991: { slidesPerView: 5.7 } }}
          keyboard={{ enabled: true }} mousewheel={{ enabled: true }}
        >
          {
            listTopLikedArtists.map(artist => (
              <SwiperSlide className="single-item" key={artist.id}>
                <Link href={`/artist/${artist.id}/${artist.slug}`}>
                  <div className="thumb">
                    <Image src={artist.imageUrl || '/images/artist-no-thumb.png'} alt={artist.name} fill sizes={SWIPER_IMAGE_SIZES} />
                  </div>
                </Link>

                <div className="des">
                  <div className="left">
                    <p>
                      <Link href={`/artist/${artist.id}/${artist.slug}`}>
                        {artist.name}
                      </Link>
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </div>
  )
}

export default TopLikedArtists