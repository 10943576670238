import { useState, useEffect } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { gql, useQuery } from '@apollo/client'
import { captureException } from '@sentry/nextjs'
import { duration } from 'lib/display'
import { Swiper, SwiperSlide } from 'swiper/react'
import { LABOR_ILLUSION_DELAY, SWIPER_IMAGE_SIZES } from 'lib/constants'
import ClientOnly from 'components/client.only'
import SwiperLoading from 'components/loading.swiper.comp'
import PlaySong from 'components/song.play.comp'
import ErrorMessage from 'components/errorMessage'

const PAGE_SIZE = 25
const LIST_TOP_SONGS_QUERY = gql`
  query listTopSongs ($page: Int!, $pageSize: Int!, $since: AWSDateTime!, $played: Boolean!, $liked: Boolean!, $downloaded: Boolean!, $shared: Boolean!) {
    listTopSongs(page: $page, pageSize: $pageSize, since: $since, played: $played, liked: $liked, downloaded: $downloaded, shared: $shared) {
      id
      title
      slug
      artist {
        id
        name
        slug
      }
      duration
      defaultImage {
        url
      }
    }
  }
`

const TopSongs = () => {
  // labor illusion state variables
  const [laborIllusion, setLaborIllusion] = useState(false)

  // set since date
  const since = new Date()
  since.setHours(0, 0, 0, 0)
  since.setMonth(since.getMonth() - 1)

  // set query variables
  const vars = {
    page: 1,
    pageSize: PAGE_SIZE,
    since: since.toISOString(),
    played: true,
    liked: false,
    downloaded: false,
    shared: false,
  }

  // excute query
  const { loading, error, data } = useQuery(
    LIST_TOP_SONGS_QUERY,
    {
      variables: vars,
    }
  )

  useEffect(() => {
    if (!data?.listTopSongs?.length) {
      setLaborIllusion(true)
      setTimeout(() => {
        setLaborIllusion(false)
      }, LABOR_ILLUSION_DELAY)
    }
  }, [])

  // initial loading
  if (loading || laborIllusion) {
    return (
      <div className="category">
        <div className="category-caption">
          <span>أشهر الأغاني</span>
        </div>
        <div className="category-content">
          <SwiperLoading />
        </div>
      </div>
    )
  }

  // error handling
  if (error) {
    captureException(error)
    return <ErrorMessage />
  }

  // in case no data found
  if (!data?.listTopSongs?.length) {
    return null
  }

  // get data
  const { listTopSongs } = data

  // display data
  return (
    <div className="category">
      <div className="category-caption">
        <span>أشهر الأغاني</span>
      </div>

      <div className="category-content">
        <Swiper spaceBetween={40} slidesPerView={2.7} breakpoints={{ 767: { slidesPerView: 3.7 }, 991: { slidesPerView: 5.7 } }}
          keyboard={{ enabled: true }} mousewheel={{ enabled: true }}
        >
          {
            listTopSongs.map(song => (
              <SwiperSlide className="single-item" key={song.id}>
                <div className="thumb">
                  <Image src={song.defaultImage?.url || '/images/song-no-thumb.png'} alt={song.title} fill sizes={SWIPER_IMAGE_SIZES} />
                  <div className="thumb-overlay">
                    <ClientOnly>
                      <PlaySong songId={song.id} showPlayOverlayButton={true} />
                    </ClientOnly>
                  </div>
                </div>

                <div className="des">
                  <div className="left">
                    <p>
                      <Link href={`/song/${song.id}/${song.slug}`}>
                        {song.title}
                      </Link>
                    </p>
                    <span>
                      <Link href={`/artist/${song.artist.id}/${song.artist.slug}`}>
                        {song.artist.name}
                      </Link>
                    </span>
                  </div>

                  <div className="right d-block">
                    <span>{duration(song.duration)}</span>
                  </div>
                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </div>
  )
}

export default TopSongs