import NextHead from 'next/head'
import { META_TYPES } from 'lib/constants'

const ROOT_WEBSITE_URL = process.env.NEXT_PUBLIC_AWTARIKA_WEBSITE_URL
const WEBSITE_TITLE = 'أوتاريكا'
const DEFAULT_DESCRIPTION = 'موقع أوتاريكا للأغاني العربية'
const DEFAULT_IMAGE = '/images/logo.png'

const Head = (props) => {
  const url = props.asPath ? `${ROOT_WEBSITE_URL}${props.asPath}` : `${ROOT_WEBSITE_URL}/`
  const title = props.title ? `${props.title} - ${WEBSITE_TITLE}` : WEBSITE_TITLE
  const description = props.description || DEFAULT_DESCRIPTION
  let image = props.image
  if (!image) {
    switch (props.type) {
      case META_TYPES.SONG:
        image = `${ROOT_WEBSITE_URL}/images/song-no-thumb.png`
        break
      case META_TYPES.ARTIST:
        image = `${ROOT_WEBSITE_URL}/images/artist-no-thumb.png`
        break
      case META_TYPES.PLAYLIST:
        image = `${ROOT_WEBSITE_URL}/images/playlist-no-thumb.png`
        break
      default:
        image = `${ROOT_WEBSITE_URL}${DEFAULT_IMAGE}`
        break
    }
  }

  return (
    <NextHead>
      <meta charSet="UTF-8" />
      <title>{title}</title>
      <meta name="application-name" content={WEBSITE_TITLE} />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link rel="icon" href="/images/favicon.ico" />

      <meta property="og:site_name" content={WEBSITE_TITLE} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={title} />

      <meta property="fb:app_id" content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID} />
      <meta property="og:locale" content="ar_AR" />

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content={`@${process.env.NEXT_PUBLIC_TWITTER_SITE}`} />

      {
        props.type === META_TYPES.SONG && <meta property="og:type" content="music.song" />
      }
      {
        props.type === META_TYPES.PLAYLIST && <meta property="og:type" content="music.playlist" />
      }
      {
        props.type !== META_TYPES.SONG &&
        props.type !== META_TYPES.PLAYLIST && (
          <meta property="og:type" content="website" />
        )
      }

      {
        props.duration && <meta property="og:music:duration" content={props.duration} />
      }
    </NextHead>
  )
}

export default Head