import { useState, useEffect } from 'react'
import Link from 'next/link'
import { gql, useQuery } from '@apollo/client'
import { captureException } from '@sentry/nextjs'
import { Placeholder } from 'react-bootstrap'
import { LABOR_ILLUSION_DELAY } from 'lib/constants'
import ErrorMessage from 'components/errorMessage'

const PAGE_SIZE = 20
const LIST_TOP_SEARCHES_QUERY = gql`
  query listTopSearches ($page: Int!, $pageSize: Int!, $since: AWSDateTime!) {
    listTopSearches(page: $page, pageSize: $pageSize, since: $since)
  }
`

const TopSearchTerms = () => {
  // labor illusion state variables
  const [laborIllusion, setLaborIllusion] = useState(false)

  // set since date
  const since = new Date()
  since.setHours(0, 0, 0, 0)
  since.setMonth(since.getMonth() - 1)

  // set query variables
  const vars = {
    page: 1,
    pageSize: PAGE_SIZE,
    since: since.toISOString(),
  }

  // excute query
  const { loading, error, data } = useQuery(
    LIST_TOP_SEARCHES_QUERY,
    {
      variables: vars,
    }
  )

  useEffect(() => {
    if (!data?.listTopSearches?.length) {
      setLaborIllusion(true)
      setTimeout(() => {
        setLaborIllusion(false)
      }, LABOR_ILLUSION_DELAY)
    }
  }, [])

  // initial loading
  if (loading || laborIllusion) {
    return (
      <div className="trending">
        <span>إبحث عن:</span>

        <Placeholder as="a" xs={2} />
        <Placeholder as="a" xs={1} />
        <Placeholder as="a" xs={3} />
        <Placeholder as="a" xs={2} />
        <Placeholder as="a" xs={1} />
        <Placeholder as="a" xs={1} />
        <Placeholder as="a" xs={1} />
        <Placeholder as="a" xs={1} />
        <Placeholder as="a" xs={1} />
        <Placeholder as="a" xs={2} />
        <Placeholder as="a" xs={1} />
        <Placeholder as="a" xs={3} />
      </div>
    )
  }

  // error handling
  if (error) {
    captureException(error)
    return <ErrorMessage />
  }

  // in case no data found
  if (!data?.listTopSearches?.length) {
    return null
  }

  // get data
  const { listTopSearches } = data

  // display data
  return (
    <div className="trending">
      <span>إبحث عن:</span>

      {
        listTopSearches.map(term => (
          <Link key={term} href={`/search-results?q=${term}`}>
            {term}
          </Link>
        ))
      }
    </div>
  )
}

export default TopSearchTerms