import { useRouter } from 'next/router'
import Head from 'components/head'
import ClientOnly from 'components/client.only'
import NewSongs from 'components/song.new.comp'
import TopSongs from 'components/song.top.comp'
import UserRecentlyPlayedSongs from 'components/song.userRecentlyPlayed.comp'
import TopLikedArtists from 'components/artist.topLiked.comp'
import TopHashtagsInSongs from 'components/hashtag.topInSongs.comp'
import TopHashtagsInPlaylists from 'components/hashtag.topInPlaylists.comp'
import TopSearchTerms from 'components/search.topTerms.comp'

export default function Home() {
  // remove old awtphase2 hashbangs /#!/
  const router = useRouter()
  if (router.asPath.match(/^\/\#\!\//)) {
    router.push(router.asPath.substring(3))
    return null
  }

  return (
    <>
      <Head />

      <div className="trending-box">
        <TopSearchTerms />
      </div>

      {/* Index Top 5899849541 */}
      <div className='hide-on-mobile'>
        <div align="center" className="mb-4">
          <ins className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client={`${ process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_PUBLISHER_CLIENT }`}
            data-ad-slot="5899849541"
            data-ad-format="auto"
            data-full-width-responsive="true">
          </ins>
        </div>
      </div>

      <NewSongs />
      <TopSongs />
      <TopLikedArtists />
      <ClientOnly>
        <UserRecentlyPlayedSongs snippet={true} />
      </ClientOnly>

      <div className="trending-box">
        <TopHashtagsInSongs />
        <TopHashtagsInPlaylists />
      </div>
    </>
  )
}
